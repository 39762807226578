import {defaultReducer} from "../utils/common";
import React, {createContext, useContext, useReducer} from "react";

export const appInitialState = {
    city: {
        id: 1,
        name: 'Новосибирск',
        lat: 82.92043,
        lng: 55.030199
    },
    cityList: [
        {
            id: 1,
            name: 'Новосибирск',
            lat: 82.92043,
            lng: 55.030199
        },
    ],

    owners: [],
    boardsTypes: [],
    boardsSizes: [],

    mapClickBusy: false,
}

export const appReducer = defaultReducer

const AppStateContext = createContext({})
const AppDispatchContext = createContext({})

export const AppProvider = ({children}) => {
    const [state,dispatch] = useReducer(appReducer,appInitialState)
    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

export const useAppState = () => {
    const context = useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider')
    }
    return context
}

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppProvider')
    }
    return context
}
