import {Checkbox, Divider, Form, Input, Typography} from "antd";
import React from "react";
import {CustomSelect, OperatorSelect} from "./fields";

const halfWidth = {display: 'inline-block', width: '50%'}

const BoardSubmitForm = ({operators, types, sizes, form, handleSubmit, initialValues}) => {
    const mainFields = [
        {
            label: "ОПЕРАТОР",
            name: 'owner_id',
            wrapperCol: {span: 22},
            rules: [{required: true, message: 'выберите оператора'}],
            component: <OperatorSelect operators={operators}/>,
            style: halfWidth
        },
        {label: "АДРЕС", name: 'address', wrapperCol: {span: 24}, component: <Input/>, style: halfWidth},
        {label: "ИНО", name: 'ino', wrapperCol: {span: 22}, component: <Input type="number"/>, style: halfWidth},
        {label: "ИНДЕКС", name: 'index', wrapperCol: {span: 24}, component: <Input/>, style: halfWidth},
        {
            label: "ТИП КОНСТРУКЦИИ",
            name: 'type_id',
            wrapperCol: {span: 22},
            rules: [{required: true, message: 'выберите тип'}],
            component: <CustomSelect options={types}/>,
            style: halfWidth
        },
        {
            label: "РАЗМЕР",
            name: 'size_id',
            wrapperCol: {span: 24},
            rules: [{required: true, message: 'выберите размер'}],
            component: <CustomSelect options={sizes}/>,
            style: halfWidth
        },
        {
            label: "КОЛИЧЕСТВО СТОРОН",
            name: 'side_count',
            wrapperCol: {span: 22},
            component: <Input type="number"/>,
            style: halfWidth
        },
        {
            label: "СУММА АРЕНДЫ",
            name: 'rent',
            wrapperCol: {span: 24},
            component: <Input type="number" placeholder="рубли"/>,
            style: halfWidth
        },
    ]
    const extraFields = [
        {
            label: "ГОСТ",
            name: 'gost',
            wrapperCol: {span: 22},
            component: <Checkbox/>,
            valuePropName: "checked",
            style: halfWidth
        },
        {label: "ФУНДАМЕНТ", name: 'fund', wrapperCol: {span: 24}, component: <Input/>, style: halfWidth},
        {
            label: "РАССТОЯНИЕ ДО ЗДАНИЯ",
            name: 'distance_to_building',
            wrapperCol: {span: 22},
            component: <Input type="number" placeholder="метры"/>,
            style: halfWidth
        },
        {
            label: "ТРЕУГОЛЬНИК ВИДИМОСТИ",
            name: 'triangle',
            wrapperCol: {span: 24},
            component: <Input type="number" placeholder="метры"/>,
            style: halfWidth
        },
    ]
    const Label = label => <Typography.Text strong>{label}</Typography.Text>
    return (
        <Form form={form} size="middle" name="board-create" layout="vertical" onFinish={handleSubmit}
              initialValues={initialValues}>
            {mainFields.map(({label, style, component, ...props}, key) => (
                <Form.Item key={key} label={Label(label)}
                           style={{...style, marginBottom: '14px'}}
                           {...props}
                >
                    {component}
                </Form.Item>
            ))}
            <Divider orientation="left" plain>ПРИМЕЧАНИЯ</Divider>
            {extraFields.map(({label, style, component, ...props}, key) => (
                <Form.Item key={key} label={Label(label)}
                           style={{...style, marginBottom: '14px'}}
                           {...props}
                >
                    {component}
                </Form.Item>
            ))}
            <Form.Item name="hide" valuePropName="checked" style={halfWidth}>
                <Checkbox>Скрыть конструкцию с карты</Checkbox>
            </Form.Item>
            <Form.Item name="to_remove" valuePropName="checked" style={halfWidth}>
                <Checkbox>Отметить для демонтажа</Checkbox>
            </Form.Item>
        </Form>
    )
}

export default BoardSubmitForm
