import React, {useEffect} from "react";
import {useAppDispatch, useAppState} from "../../../store/app-reducer";
import {BoardModal} from "../../shared/forms/BoardModal";
import CityMap from "../../shared/map/CityMap";
import {boardsAPI} from "../../../api/boards";


const MapPage = () => {
    const {city} = useAppState()
    const appDispatch = useAppDispatch()
    useEffect(()=>{
        boardsAPI
            .getCities()
            .then(({data})=> appDispatch({type: 'cityList', payload: data.data}))
            .catch(e => console.error(e))
    },[appDispatch])
    return (
        <>
            <CityMap city={city}/>
            <BoardModal/>
        </>
    )
}

export default MapPage
