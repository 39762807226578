import React, {createContext, useContext, useReducer, useMemo} from "react";
import {boardsAPI} from "../api/boards";

export const boardsInitialState = {
    boardsFetched: [],
    currentBoardCords: [],

    showHidden: false,

    excludeQuery: {
        ownersID: [],
        typesID: [],
        sizesID: []
    }
}

export const boardsReducer = (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case 'boardsFetched':
            return {...state, boardsFetched: payload}
        case 'showHidden':
            return {...state, showHidden: payload}
        case 'currentBoardCords':
            return {...state, currentBoardCords: payload}
        case 'excludeQuery.ownersID':
            return {
                ...state,
                excludeQuery: {
                    ...state.excludeQuery,
                    ownersID: payload
                }
            }
        case 'excludeQuery.sizesID':
            return {
                ...state,
                excludeQuery: {
                    ...state.excludeQuery,
                    sizesID: payload
                }
            }
        case 'excludeQuery.typesID':
            return {
                ...state,
                excludeQuery: {
                    ...state.excludeQuery,
                    typesID: payload
                }
            }
        default:
            return state
    }
}

const BoardsStateContext = createContext({})
const BoardsDispatchContext = createContext({})
const BoardsThunkContext = createContext({})

export const BoardsProvider = ({children}) => {
    const [state, dispatch] = useReducer(boardsReducer, boardsInitialState)
    const actions = useMemo(()=>({
        getBoards: filter => {
            return boardsAPI.getBoards(filter)
                .then(({data}) => dispatch({type: 'boardsFetched', payload: data.data}))
        }
    }),[])
    return (
        <BoardsStateContext.Provider value={state}>
            <BoardsDispatchContext.Provider value={dispatch}>
                <BoardsThunkContext.Provider value={actions}>
                    {children}
                </BoardsThunkContext.Provider>
            </BoardsDispatchContext.Provider>
        </BoardsStateContext.Provider>
    )
}

export const useBoardsState = () => {
    const context = useContext(BoardsStateContext)
    if (context === undefined) {
        throw new Error('useBoardsState must be used within a BoardsProvider')
    }
    return context
}

export const useBoardsDispatch = () => {
    const context = useContext(BoardsDispatchContext)
    if (context === undefined) {
        throw new Error('useBoardsDispatch must be used within a BoardsProvider')
    }
    return context
}

export const useBoardsThunk = () => {
    const context = useContext(BoardsThunkContext)
    if (context === undefined) {
        throw new Error('useBoardsThunk must be used within a BoardsProvider')
    }
    return context
}
