import React from "react";
import {Layout} from "antd";
import MapPage from "./Components/pages/MapPage/MapPage";
import {AppProvider} from "./store/app-reducer";
import {BoardsProvider} from "./store/boards-reducer";
import {FormsProvider} from "./store/forms-reducer";

function App() {
    return (
        <div className="App">
            <AppProvider>
                <FormsProvider>
                    <Layout style={{height: '100vh'}}>
                        <BoardsProvider>
                            <MapPage/>
                        </BoardsProvider>
                    </Layout>
                </FormsProvider>
            </AppProvider>
        </div>
    );
}

export default App;
