import instance from "./instance";

export const boardsAPI = {
    getBoards: (filter) => instance.post('api/v1/pms/places', {filter}),
    getCities: () => instance.get('api/v1/pms/catalog/cities'),
    getOwners: () => instance.get('api/v1/pms/catalog/owners'),
    getSizes: () => instance.get('api/v1/pms/catalog/place_sizes'),
    getTypes: () => instance.get('api/v1/pms/catalog/place_types'),
    addBoard: data => instance.post(`api/v1/pms/place`, data),
    editBoard: (id,data) => instance.post(`api/v1/pms/place/${id}`, data),
    getBoard: id => instance.get(`api/v1/pms/place/${id}`),
    deleteBoard: id => instance.delete(`api/v1/pms/place/${id}`),
    moveBoard: (id,data) => instance.post(`api/v1/pms/place/move/${id}`, data)
}
