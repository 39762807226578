import React, {createContext, useContext, useReducer} from "react";
import {defaultReducer} from "../utils/common";

export const BOARD_FORM_INITIAL = {
    id: null,
    owner_id: null,
    address: null,
    ino: null,
    index: null,
    type_id: null,
    size_id: null,
    side_count: null,
    rent: null,
    hide: false,
    to_remove: false,

    gost: false,
    fund: null,
    distance_to_building: null,
    triangle: null,

    lat: null,
    lng: null,
}

export const formsInitialState = {
    isModalFormOpen: false,
    isModalFormAddEnabled: false,
    isBoardsFormEdit: false,
    boardsFormInitial: BOARD_FORM_INITIAL
}

export const formsReducer = defaultReducer

const FormsStateContext = createContext({})
const FormsDispatchContext = createContext({})

export const FormsProvider = ({children}) => {
    const [state,dispatch] = useReducer(formsReducer,formsInitialState)
    return (
        <FormsStateContext.Provider value={state}>
            <FormsDispatchContext.Provider value={dispatch}>
                {children}
            </FormsDispatchContext.Provider>
        </FormsStateContext.Provider>
    )
}

export const useFormsState = () => {
    const context = useContext(FormsStateContext)
    if (context === undefined) {
        throw new Error('useFormsState must be used within a FormsProvider')
    }
    return context
}

export const useFormsDispatch = () => {
    const context = useContext(FormsDispatchContext)
    if (context === undefined) {
        throw new Error('useFormsDispatch must be used within a FormsProvider')
    }
    return context
}
