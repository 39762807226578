import React, {useEffect,useCallback} from "react";
import {Button, Checkbox, Col, Collapse, Row, Space} from "antd";
import {useAppDispatch, useAppState} from "../../../store/app-reducer";
import {useFormsDispatch, useFormsState} from "../../../store/forms-reducer";
import {useBoardsDispatch, useBoardsState} from "../../../store/boards-reducer";


const Panel = ({zIndex,map}) => {
    const {cityList,city,owners,boardsTypes,boardsSizes,mapClickBusy} = useAppState()
    const appDispatch = useAppDispatch()

    const {showHidden} = useBoardsState()
    const boardsDispatch = useBoardsDispatch()

    const toggleHidden = () => boardsDispatch({type: 'showHidden', payload: !showHidden})

    const {isModalFormAddEnabled} = useFormsState()
    const formsDispatch = useFormsDispatch()

    const toggleEnabled = () => formsDispatch({type: 'isModalFormAddEnabled', payload: !isModalFormAddEnabled})
    const openModal = useCallback(e => {
        boardsDispatch({type: 'currentBoardCords', payload: e.get('coords')})
        formsDispatch({type: 'isModalFormOpen', payload: true})
    },[boardsDispatch,formsDispatch])

    const onSelect = (id) => {
        const payload = cityList.find(c => c.id === id)
        appDispatch({type: 'city',payload})
    }
    useEffect(()=>{
        if (!map) return () => {}
        if (isModalFormAddEnabled) {
            map.events.add('click', openModal)
            map.cursors.push('pointer')
            appDispatch({type: 'mapClickBusy', payload: true})
        } else {
            map.events.remove('click', openModal)
            map.cursors.push('grab')
            appDispatch({type: 'mapClickBusy', payload: false})
        }
        return ()=>{
            map.events.remove('click', openModal)
        }
    },[map,isModalFormAddEnabled,openModal,appDispatch])
    const ownersChange = v => {
        const payload = owners
            .map(f=>f.id)
            .filter(o => !v.includes(o))
        boardsDispatch({type: 'excludeQuery.ownersID', payload})
    }
    const typesChange = v => {
        const payload = boardsTypes
            .map(f=>f.id)
            .filter(o => !v.includes(o))
        boardsDispatch({type: 'excludeQuery.typesID', payload})
    }
    const sizesChange = v => {
        const payload = boardsSizes
            .map(f=>f.id)
            .filter(o => !v.includes(o))
        boardsDispatch({type: 'excludeQuery.sizesID', payload})
    }
    return (
        <Space direction="vertical" style={{
            position: 'absolute', zIndex,
            width: '320px', padding: '5px 10px',
            maxHeight: '100%', overflowY: 'auto',
            background: 'white'
        }}>
            {/*<CustomSelect options={cityList} defaultValue={city && city.id} onChange={onSelect}/>*/}
            {
                isModalFormAddEnabled ? <Button onClick={toggleEnabled}>Отменить</Button>
                    : <Button type="primary" onClick={toggleEnabled} disabled={mapClickBusy}>Добавить</Button>
            }
            <Checkbox checked={showHidden} onChange={toggleHidden}>Показать скрытые</Checkbox>
            <Collapse bordered={false}>
                <Collapse.Panel header="Операторы">
                    <Checkbox.Group defaultValue={owners.map(b=>b.id)} onChange={ownersChange}>
                        <Row>
                            {owners.map(({short_name,id})=> (
                                <Col key={id}  span={24}><Checkbox value={id}>{short_name}</Checkbox></Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Collapse.Panel>
                <Collapse.Panel header="Типы">
                    <Checkbox.Group defaultValue={boardsTypes.map(b=>b.id)} onChange={typesChange}>
                        <Row>
                            {boardsTypes.map(({name,id})=> (
                                <Col key={id} span={24}><Checkbox value={id}>{name}</Checkbox></Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Collapse.Panel>
                <Collapse.Panel header="Размеры">
                    <Checkbox.Group defaultValue={boardsSizes.map(b=>b.id)} onChange={sizesChange}>
                        <Row>
                            {boardsSizes.map(({name,id})=> (
                                <Col key={id} span={24}><Checkbox value={id}>{name}</Checkbox></Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Collapse.Panel>
            </Collapse>
        </Space>
    )
}

export default Panel

