import React, {useEffect} from "react";
import {Form, Modal} from "antd";
import BoardSubmitForm from "./BoardSubmitForm";
import {useAppDispatch, useAppState} from "../../../store/app-reducer";
import {boardsAPI} from "../../../api/boards";
import {useBoardsThunk, useBoardsState} from "../../../store/boards-reducer";
import {BOARD_FORM_INITIAL, useFormsDispatch, useFormsState} from "../../../store/forms-reducer";


export const BoardModal = () => {
    const [form] = Form.useForm()
    const {owners, boardsTypes, boardsSizes, city} = useAppState()
    const appDispatch = useAppDispatch()

    const {currentBoardCords} = useBoardsState()
    const {getBoards} = useBoardsThunk()

    const {isModalFormOpen, isBoardsFormEdit, boardsFormInitial} = useFormsState()
    const formsDispatch = useFormsDispatch()

    useEffect(()=>{
        boardsAPI.getOwners()
            .then(({data})=> appDispatch({type: 'owners', payload: data.data}))
            .catch(e=>console.error(e))
        boardsAPI.getSizes()
            .then(({data})=> appDispatch({type: 'boardsSizes', payload: data.data}))
            .catch(e=>console.error(e))
        boardsAPI.getTypes()
            .then(({data})=> appDispatch({type: 'boardsTypes', payload: data.data}))
            .catch(e=>console.error(e))
    }, [appDispatch])

    useEffect(()=>{
        if (form.setFieldsValue) form.setFieldsValue(boardsFormInitial)
    }, [form,boardsFormInitial])

    const editBoard = (id,data) => {
        boardsAPI.editBoard(id,data)
            .then(()=>{
                formsDispatch({type: 'isModalFormOpen', payload: false})
                formsDispatch({type: 'isBoardsFormEdit', payload: false})
                getBoards({city: [city.id]})
            })

    }
    const addBoard = (data) => {
        boardsAPI.addBoard(data)
            .then(()=>{
                formsDispatch({type: 'isModalFormOpen', payload: false})
                formsDispatch({type: 'isModalFormAddEnabled', payload: false})
                getBoards({city: [city.id]})
            })
    }

    const onCancel = () => {
        formsDispatch({type: 'isModalFormOpen', payload: false})
        formsDispatch({type: 'isBoardsFormEdit', payload: false})
        formsDispatch({type: 'boardsFormInitial', payload: BOARD_FORM_INITIAL})
    }

    const handleSubmit = ({
        owner_id, type_id, size_id,
        address, ino, index, side_count, rent, hide,
        gost, fund, distance_to_building, triangle, to_remove
    }) => {
        const {id, lat, lng } = boardsFormInitial
        const data = {
            owner: { id: owner_id },
            type: { id: type_id },
            size: { id: size_id },
            visible: !hide,
            address, ino, index, side_count, rent, to_remove,
            properties: { gost, fund, distance_to_building, triangle },
            lat: lat || currentBoardCords[0],
            lng: lng || currentBoardCords[1],
        }
        isBoardsFormEdit ? editBoard(id,data) : addBoard(data)
    }

    return (
        <Modal visible={isModalFormOpen} onCancel={onCancel} closable={false} mask={false} onOk={form.submit}
               title={isBoardsFormEdit ? "Изменение Рекламной Конструкции" : "Добавление Рекламной Конструкции"}
               okText="Применить" cancelText="Отменить"
        >
            <BoardSubmitForm form={form} handleSubmit={handleSubmit} initialValues={boardsFormInitial}
                             operators={owners} types={boardsTypes} sizes={boardsSizes}
            />
        </Modal>
    )
}
