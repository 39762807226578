import React from "react";
import {Select} from "antd";

export const OperatorSelect = ({operators,...props}) => {
    const options = operators.map(({ short_name, id }) => ({ id, name: short_name }))
    return <CustomSelect options={options} {...props}/>
}

export const CustomSelect = ({options,...props}) => {
    return (
        <Select {...props}>
            {options.map(({name,id})=>(
                <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
        </Select>
    )
}
