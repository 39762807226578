import React from "react";
import SvgSprite from 'react-svg-sprite'

const SVGSprite = () => (
    <SvgSprite
        symbols={[
            {
                name: 'billboard',
                svg: `<svg width="20" height="21" viewBox="0 0 20 21" fill="" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="11.8696" rx="1" fill="white"/>
<rect x="8.18188" y="11.8696" width="3.63636" height="9.13043" fill="" stroke="none"/>
</svg>`
            },
            {
                name: 'cityboard',
                svg: `<svg width="24" height="17" viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="23.5" height="16.5" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
<text fill="" stroke="none" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="14" font-weight="500" letter-spacing="0em"><tspan x="4.40527" y="12.7852">&#x441;&#x431;</tspan></text>
</svg>
`
            },
            {
                name: 'fences',
                svg: `<svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
<rect x="5" y="11" width="2" height="9" fill="" stroke="none"/>
<rect x="11" y="11" width="2" height="9" fill="" stroke="none"/>
<rect x="0.25" y="0.25" width="11.5" height="11.5" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
<rect x="17" y="11" width="2" height="9" fill="" stroke="none"/>
<rect x="12.25" y="0.25" width="11.5" height="11.5" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
</svg>
`
            },
            {
                name: 'lightbox',
                svg: `<svg width="14" height="22" viewBox="0 0 14 22" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="13.5" height="21.5" rx="0.75" fill="white" stroke-width="0.5"/>
<path d="M7.00061 6.12538C7.28918 6.12538 7.52368 5.87442 7.52368 5.5656V3.55977C7.52368 3.25096 7.28918 3 7.00061 3C6.71239 3 6.47754 3.25096 6.47754 3.55977V5.5656C6.47754 5.87442 6.71239 6.12538 7.00061 6.12538Z" stroke="none" fill=""/>
<path d="M2.47899 7.80195C2.57748 7.90772 2.70868 7.96557 2.84848 7.96557C2.98829 7.96557 3.11949 7.90772 3.21832 7.80158C3.42218 7.58342 3.42218 7.22854 3.21832 7.01038L1.89256 5.59197C1.79373 5.4862 1.66288 5.42834 1.52307 5.42834C1.38326 5.42834 1.25172 5.4862 1.15324 5.59233C1.05441 5.69773 1 5.83813 1 5.98738C1 6.137 1.05441 6.2774 1.15324 6.38317L2.47899 7.80195Z" fill="" stroke="none"/>
<path d="M11.1518 7.96521C11.2909 7.96521 11.4228 7.90735 11.5217 7.80158L12.8474 6.38243C12.9456 6.2774 13 6.137 13 5.98738C13 5.8385 12.9456 5.69736 12.8467 5.5916C12.7486 5.4862 12.6174 5.42834 12.4776 5.42834C12.3385 5.42834 12.2073 5.4862 12.1084 5.59197L10.7827 7.01038C10.6832 7.11615 10.6301 7.25655 10.6301 7.40543C10.6301 7.55505 10.6832 7.69582 10.7827 7.80158C10.8808 7.90809 11.0117 7.96521 11.1518 7.96521Z" fill="" stroke="none"/>
<path d="M7.02165 7.71387C4.92351 7.71387 3.21655 9.54023 3.21655 11.7852C3.21655 13.4012 4.08088 15.1789 5.27096 16.0902V18.4694C5.27096 18.7192 5.42867 18.9377 5.65491 19L7.02199 19L8.38839 19C8.61428 18.9374 8.77234 18.7177 8.77234 18.4694V16.0902C9.96277 15.1789 10.8264 13.4008 10.8264 11.7852C10.8261 9.54023 9.1191 7.71387 7.02165 7.71387ZM7.74686 18.0437L7.02199 18.2449L6.29713 18.0437V17.3749H7.74686V18.0437ZM7.96277 15.3443C7.76166 15.4682 7.66283 15.7206 7.72344 15.9612C7.72964 15.9863 7.73687 16.0117 7.74617 16.0353V16.5505H6.29644V16.0353C6.30608 16.0117 6.314 15.9859 6.3202 15.9612C6.37978 15.7217 6.28129 15.4674 6.07984 15.3443C5.0492 14.7105 4.24203 13.1484 4.24203 11.786C4.24203 10.1464 5.48859 8.81204 7.02199 8.81204C8.55402 8.81204 9.80023 10.1464 9.80023 11.786C9.80092 13.1484 8.99376 14.7109 7.96277 15.3443Z" fill="" stroke="none"/>
<path d="M7.96277 15.3443C7.76166 15.4682 7.66283 15.7206 7.72344 15.9612C7.72964 15.9863 7.73687 16.0117 7.74617 16.0353V16.5505H6.29644V16.0353C6.30608 16.0117 6.314 15.9859 6.3202 15.9612C6.37978 15.7217 6.28129 15.4674 6.07984 15.3443C5.0492 14.7105 4.24203 13.1484 4.24203 11.786C4.24203 10.1464 5.48859 8.81204 7.02199 8.81204C8.55402 8.81204 9.80023 10.1464 9.80023 11.786C9.80092 13.1484 8.99376 14.7109 7.96277 15.3443Z" fill="" stroke="none"/>
<path d="M7.74686 18.0437L7.02199 18.2449L6.29713 18.0437V17.3749H7.74686V18.0437Z" fill="" stroke="none"/>
</svg>
`
            },
            {
                name: 'other',
                svg: `<svg width="9" height="14" viewBox="0 0 9 14" fill="" xmlns="http://www.w3.org/2000/svg">
<path d="M3.19922 9.23828C3.19922 8.45898 3.2959 7.83789 3.48926 7.375C3.68262 6.91211 4.06348 6.40527 4.63184 5.85449C5.20605 5.29785 5.56934 4.90234 5.72168 4.66797C5.95605 4.31055 6.07324 3.92383 6.07324 3.50781C6.07324 2.95703 5.93555 2.53809 5.66016 2.25098C5.39062 1.95801 4.99219 1.81152 4.46484 1.81152C3.96094 1.81152 3.55371 1.95508 3.24316 2.24219C2.93848 2.52344 2.78613 2.90723 2.78613 3.39355H0.650391C0.662109 2.35645 1.01367 1.53613 1.70508 0.932617C2.40234 0.329102 3.32227 0.0273438 4.46484 0.0273438C5.64258 0.0273438 6.55957 0.326172 7.21582 0.923828C7.87793 1.52148 8.20898 2.35645 8.20898 3.42871C8.20898 4.38379 7.76367 5.32422 6.87305 6.25L5.79199 7.31348C5.40527 7.75293 5.20605 8.39453 5.19434 9.23828H3.19922ZM3.0498 11.9717C3.0498 11.626 3.1582 11.3477 3.375 11.1367C3.5918 10.9199 3.88477 10.8115 4.25391 10.8115C4.62891 10.8115 4.9248 10.9229 5.1416 11.1455C5.3584 11.3623 5.4668 11.6377 5.4668 11.9717C5.4668 12.2939 5.36133 12.5635 5.15039 12.7803C4.93945 12.9971 4.64062 13.1055 4.25391 13.1055C3.86719 13.1055 3.56836 12.9971 3.35742 12.7803C3.15234 12.5635 3.0498 12.2939 3.0498 11.9717Z" fill="" stroke="none" />
</svg>
`
            },
            {
                name: 'pillar',
                svg: `<svg width="17" height="23" viewBox="0 0 17 23" fill="" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 5.55425C0.25 5.29566 0.383215 5.0553 0.602501 4.91825L6.6025 1.16825C7.10204 0.856039 7.75 1.21517 7.75 1.80425V17.307C7.75 17.6196 7.55607 17.8995 7.26334 18.0092L1.26334 20.2592L1.35112 20.4933L1.26334 20.2592C0.773029 20.4431 0.25 20.0807 0.25 19.557V5.55425Z" fill="white" stroke="" stroke-width="0.5"/>
<path d="M9.25 1.80425C9.25 1.21517 9.89796 0.856039 10.3975 1.16825L16.3975 4.91825C16.6168 5.0553 16.75 5.29565 16.75 5.55425L16.75 19.557C16.75 20.0807 16.227 20.4431 15.7367 20.2592L9.73666 18.0092C9.44393 17.8995 9.25 17.6196 9.25 17.307L9.25 1.80425Z" fill="white" stroke="" stroke-width="0.5"/>
<path d="M1.51926 22C1.40915 22 1.37989 21.848 1.48212 21.8072L8.46286 19.0149C8.4867 19.0053 8.5133 19.0053 8.53714 19.0149L15.5179 21.8072C15.6201 21.848 15.5908 22 15.4807 22H1.51926Z" fill="" stroke=""/>
</svg>
`
            },
            {
                name: 'pointer',
                svg: `<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="8.93738" width="8" height="8" fill="white" stroke=""/>
<path d="M21.9352 12.1829C22.2207 12.3795 22.2244 12.8 21.9423 13.0016L9.2302 22.0846C8.9009 22.3198 8.44308 22.0868 8.43954 21.6821L8.28296 3.73933C8.27943 3.33462 8.73311 3.09362 9.06648 3.32313L21.9352 12.1829Z" fill="white" stroke=""/>
</svg>
`
            },
            {
                name: 'poster',
                svg: `<svg width="14" height="23" viewBox="0 0 14 23" fill="" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="13.5" height="21.5" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
<rect x="3" y="22" width="8" height="1" fill="" stroke="none"/>
</svg>
`
            },
            {
                name: 'stand',
                svg: `<svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="23.5" height="11.9091" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
<rect x="5" y="12" width="2" height="9" fill="" stroke="none"/>
<rect x="11" y="12" width="2" height="9" fill="" stroke="none"/>
<rect x="17" y="12" width="2" height="9" fill="" stroke="none"/>
<text fill="" stroke="none" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10" font-weight="500" letter-spacing="0em"><tspan x="9.29492" y="9.41797">a</tspan></text>
</svg>
`
            },
            {
                name: 'supersite',
                svg: `<svg width="20" height="21" viewBox="0 0 20 21" fill="" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="19.5" height="11.3696" rx="0.75" fill="white" stroke="" stroke-width="0.5"/>
<rect x="8.18188" y="11.8696" width="3.63636" height="9.13043" fill="" stroke="none"/>
<text fill="" stroke="none" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10" font-weight="500" letter-spacing="0em"><tspan x="7.38281" y="9.41797">&#x441;</tspan></text>
</svg>
`
            },
            {
                name: 'tumb',
                svg: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="9.5" fill="white" stroke=""/>
<path d="M11.4834 14C11.4105 13.8587 11.3467 13.6286 11.292 13.3096C10.7633 13.861 10.1162 14.1367 9.35059 14.1367C8.60775 14.1367 8.00163 13.9248 7.53223 13.501C7.06283 13.0771 6.82812 12.5531 6.82812 11.9287C6.82812 11.1403 7.11979 10.5365 7.70312 10.1172C8.29102 9.69336 9.12956 9.48145 10.2188 9.48145H11.2373V8.99609C11.2373 8.61328 11.1302 8.30794 10.916 8.08008C10.7018 7.84766 10.376 7.73145 9.93848 7.73145C9.56022 7.73145 9.25033 7.82715 9.00879 8.01855C8.76725 8.2054 8.64648 8.44466 8.64648 8.73633H6.98535C6.98535 8.33073 7.11979 7.95247 7.38867 7.60156C7.65755 7.24609 8.02214 6.9681 8.48242 6.76758C8.94727 6.56706 9.46452 6.4668 10.0342 6.4668C10.9001 6.4668 11.5905 6.68555 12.1055 7.12305C12.6204 7.55599 12.8848 8.16667 12.8984 8.95508V12.291C12.8984 12.9564 12.9919 13.4873 13.1787 13.8838V14H11.4834ZM9.6582 12.8037C9.98633 12.8037 10.2939 12.724 10.5811 12.5645C10.8727 12.4049 11.0915 12.1908 11.2373 11.9219V10.5273H10.3418C9.72656 10.5273 9.264 10.6344 8.9541 10.8486C8.64421 11.0628 8.48926 11.3659 8.48926 11.7578C8.48926 12.0768 8.59408 12.332 8.80371 12.5234C9.0179 12.7103 9.30273 12.8037 9.6582 12.8037Z" fill="" stroke="none"/>
</svg>
`
            },
            {
                name: 'video',
                svg: `<svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
<rect x="0.25" y="0.25" width="19.5" height="11.3696" rx="0.75" fill="white" stroke-width="0.5"/>
<rect x="8.18188" y="11.8696" width="3.63636" height="9.13043" fill="" stroke="none"/>
<circle cx="10" cy="6" r="3.75" stroke="" fill="none" stroke-width="0.5"/>
<path d="M12.4123 6.26083L9.13502 8.24752L9.10084 4.33164L12.4123 6.26083Z" fill="" stroke="none"/>
</svg>
`
            }
        ]}
    />
)

export default SVGSprite
