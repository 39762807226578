import React, {useState, useRef, useEffect} from "react";
import {Map} from "react-yandex-maps";
import Panel from "./Panel";
import {Markers} from "./Markers/Markers";
import {useBoardsState, useBoardsThunk} from "../../../store/boards-reducer";

const mapZIndex = 100

const CityMap = ({city}) => {
    const [Ymaps, setYmaps] = useState(null)
    const [mapState, setMapState] = useState({
        center: [city.lng, city.lat],
        zoom: 12,
        options: {}
    })
    const onLoad = ymaps => setYmaps(ymaps)
    const mapRef = useRef(null)

    const {boardsFetched} = useBoardsState()
    const {getBoards} = useBoardsThunk()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setMapState(s => ({...s, center: [city.lng, city.lat]}))
        const fetch = () => {
            setLoading(true)
            getBoards({city: [city.id]})
                .finally(() => setLoading(false))
        }
        fetch()
    }, [city, getBoards])

    const {excludeQuery} = useBoardsState()
    const [boards, setBoards] = useState(boardsFetched)
    useEffect(() => {
        const boards = boardsFetched
            .filter(board => board.owner && board.owner.color && board.type)
            .filter(board => {
                const {ownersID, typesID, sizesID} = excludeQuery
                const {owner, type, size} = board
                return !ownersID.includes(owner.id) &&
                    !typesID.includes(type.id) &&
                    !sizesID.includes(size.id)
            })
        setBoards(boards)
    }, [excludeQuery, boardsFetched])

    if (loading) return <div/>
    return (
        <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <Map style={{width: '100%', height: '100%', zIndex: mapZIndex}} instanceRef={mapRef} onLoad={onLoad}
                 state={mapState} modules={["templateLayoutFactory", "layout.ImageWithContent"]}
            >
                <Panel zIndex={mapZIndex + 1} Ymaps={Ymaps} map={mapRef.current}/>
                <Markers boards={boards} Ymaps={Ymaps} map={mapRef.current}/>
            </Map>
        </div>
    )
}

export default CityMap
