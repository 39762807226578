import React from "react";
import {Placemark} from "react-yandex-maps";
import './markers.scss'
import SVGSprite from "../../../../svg-sprite/svg-sprite";
import {Modal, Space, Typography} from "antd";
import {boardsAPI} from "../../../../api/boards";
import {useAppDispatch, useAppState} from "../../../../store/app-reducer";
import {useBoardsThunk, useBoardsState} from "../../../../store/boards-reducer";
import {useFormsDispatch} from "../../../../store/forms-reducer";


export const Markers = ({ boards, Ymaps, map }) => {
    const { city, mapClickBusy } = useAppState()
    const appDispatch = useAppDispatch()
    const formsDispatch = useFormsDispatch()
    const {showHidden} = useBoardsState()
    const {getBoards} = useBoardsThunk()
    if (!Ymaps) return <div/>
    const createMarkerLayout = (owner, type, visible, to_remove) => {
        const { color } = owner
        return Ymaps.templateLayoutFactory.createClass(`
        <div class="board-marker ${to_remove && '_demolish'}" style="opacity: ${visible ? 1 : .5};">
            <div class="board-marker__circle" style="background: ${color};">
                <div class="board-marker__circle-inner">
                    <svg class="board-marker__icon" style="fill: ${color}; stroke: ${color}">
                        <use xlink:href="#${type.icon}" />
                    </svg>
                </div>
            </div>
            <div class="board-marker__pin" style="background: ${color}"></div>
        </div>
        `)
    }
    const createPopupLayout = (boardID) => {
        const deleteAction = () => {
            closePopupWithUnsub()
            Modal.confirm({
                title: 'УДАЛЕНИЕ РЕЛАМНОЙ КОНСТРУКЦИИ',
                bodyStyle: { textAlign: 'center' },
                content: <Space direction="vertical" align="center" style={{ width: '100%' }}>
                    <Typography.Text strong>Удалить конструкцию {boardID}</Typography.Text>
                    <Typography.Text strong type="danger">Удалённую конструкцию не <br/> возможно будет
                        восстановить</Typography.Text>
                </Space>,
                icon: '',
                okText: 'Удалить',
                onOk: async () => {
                    await boardsAPI.deleteBoard(boardID)
                    getBoards({ city: [city.id] })
                },
                cancelText: 'Отмена',
                okButtonProps: { type: 'danger' }
            })
        }
        const editAction = async () => {
            closePopupWithUnsub()
            const data = await boardsAPI.getBoard(boardID)
                .then(({ data }) => data.data)
                .catch(e => console.error(e))
            if (!data) return
            const {
                owner, type, size,
                address, ino, index, side_count, rent, visible,
                lat, lng, properties, to_remove
            } = data
            const fields = {
                id: boardID,
                address, ino, index, side_count, rent,
                owner_id: owner.id,
                type_id: type.id,
                size_id: size.id,
                hide: !visible,
                lat, lng, to_remove,
                ...properties
            }
            formsDispatch({ type: 'boardsFormInitial', payload: fields })
            formsDispatch({ type: 'isBoardsFormEdit', payload: true })
            formsDispatch({ type: 'isModalFormOpen', payload: true })
        }
        const moveModal = e => {
            const [lat,lng] = e.get('coords')
            Modal.confirm({
                title: 'ПЕРЕМЕЩЕНИЕ РЕЛАМНОЙ КОНСТРУКЦИИ',
                bodyStyle: { textAlign: 'center' },
                content: <Space direction="vertical" align="center" style={{ width: '100%' }}>
                    <Typography.Text strong>Переместить конструкцию {boardID} ?</Typography.Text>
                </Space>,
                icon: '',
                okText: 'Переместить',
                onOk: async () => {
                    closePopupWithUnsub()
                    map.events.remove('click', moveModal)
                    await boardsAPI.moveBoard(boardID, {lat,lng})
                    getBoards({ city: [city.id] })
                },
                onCancel: () => {
                    closePopupWithUnsub()
                    map.events.remove('click', moveModal)
                },
                cancelText: 'Отмена',
                okButtonProps: { type: 'primary' }
            })
        }
        const moveAction = () => {
            map.balloon.close()
            map.cursors.push('crosshair')
            map.events.add('click', moveModal)
        }
        return Ymaps.templateLayoutFactory.createClass(`
        <div class="custom-popup">
            <button id="ypopup-action-edit">Изменить</button>
            <button id="ypopup-action-move">Переместить</button>
            <button id="ypopup-action-delete">Удалить</button>
        </div>
        `, {
            build: function () {
                this.constructor.superclass.build.call(this)
                this._$element = this.getParentElement().querySelector('.custom-popup')
                this._$element.addEventListener('click', this.click.bind(this))
            },
            clear: function () {
                this._$element.removeEventListener('click', this.click)
                this.constructor.superclass.clear.call(this)
            },
            click: function ({ target }) {
                if (target.id === 'ypopup-action-delete' && !mapClickBusy) {
                    deleteAction()
                }
                if (target.id === 'ypopup-action-edit' && !mapClickBusy) {
                    editAction().catch(e => console.error(e))
                }
                if (target.id === 'ypopup-action-move' && !mapClickBusy) {
                    moveAction()
                }
            }

        })
    }
    const placeMarks = boards
        .map(({ lat, lng, type, owner, id, visible, to_remove }) => ({
            geometry: [lat, lng],
            options: {
                iconLayout: createMarkerLayout(owner, type, visible, to_remove),
                iconOffset: [-24, -48],
                iconShape: { type: 'Circle', coordinates: [24, 24], radius: 24 },
            },
            boardData: { id, visible }
        }))
    const visibleMarkers = placeMarks.filter(({boardData}) => boardData.visible)
    const hiddenMarkers = placeMarks.filter(({boardData}) => !boardData.visible)
    const closePopupWithUnsub = () => {
        map.balloon.close()
        map.cursors.push('grab')
        map.events.remove('click', closePopupWithUnsub)
        appDispatch({type: 'mapClickBusy', payload: false})
    }
    const showPopup = (geometry, boardID) => {
        if (!mapClickBusy) {
            map.balloon.open(geometry, {}, {
                layout: createPopupLayout(boardID)
            })
            appDispatch({type: 'mapClickBusy', payload: true})
            map.events.add('click', closePopupWithUnsub)
        }
    }

    return (
        <>
            {visibleMarkers.map(({ boardData, ...marker }, ind) => (
                <Placemark
                    key={ind}
                    onClick={() => showPopup(marker.geometry, boardData.id)}
                    {...marker}
                />
            ))}
            {showHidden && hiddenMarkers.map(({ boardData, ...marker }, ind) => (
                <Placemark
                    key={ind}
                    onClick={() => showPopup(marker.geometry, boardData.id)}
                    {...marker}
                />
            ))}
            <SVGSprite/>
        </>
    )
}
